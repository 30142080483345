'use strict'
const _ = require('lodash')
const isMasterPage = pageJson => _.get(pageJson, ['structure', 'type']) === 'Document'

function addMissingCustomMenusToMenuCollection(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewer, rendererModel, magicObject) {
    if (isMasterPage(pageJson) && magicObject.isExperimentOpen('dm_fixMissingRefsToCustomMenus')) {
        const {document_data} = pageJson.data
        const customMenuReferences = _(document_data)
            .pickBy({type: 'CustomMenu'})
            .keys()
            .map(k => `#${k}`)
            .value()

        const {CUSTOM_MENUS} = document_data
        const existingReferencedMenus = _.filter(CUSTOM_MENUS.menus, menuQuery => document_data[menuQuery.replace('#', '')])
        const missingRefs = _.difference(customMenuReferences, existingReferencedMenus)
        CUSTOM_MENUS.menus = existingReferencedMenus.concat(missingRefs)
    }
}


module.exports = {
    exec: addMissingCustomMenusToMenuCollection
}
